import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const useBackend = process.env.REACT_APP_USE_BE_TRANSLATIONS === 'true';

class CustomBackend {
	constructor(services, options = {}) {
		const satelliteUrl = window.localStorage.getItem('satelliteUrl');
		const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;

		this.init(services, options);
		this.primaryBackend = useBackend ? new HttpApi(services, { loadPath: `${hostUrl}/biro/i18n/{{lng}}` }) : null;
		this.fallbackBackend = new HttpApi(services, { loadPath: '/locales/{{lng}}.json' });
	}

	init(services, options = {}) {
		this.services = services;
		this.options = options;
	}

	read(language, namespace, callback) {
		if (this.primaryBackend) {
			this.primaryBackend.read(language, namespace, (err, data) => {
				if (err || !data) {
					console.warn('Primary backend failed, trying fallback backend', err);
					this.fallbackBackend.read(language, namespace, callback);
				} else {
					callback(null, data);
				}
			});
		} else {
			this.fallbackBackend.read(language, namespace, callback);
		}
	}
}

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.use({
		type: 'backend',
		init: function (services, backendOptions, i18nextOptions) {
			this.backend = new CustomBackend(services, backendOptions);
		},
		read: function (language, namespace, callback) {
			this.backend.read(language, namespace, callback);
		},
		create: function (languages, namespace, key, fallbackValue) {
			if (this.backend.create) {
				this.backend.create(languages, namespace, key, fallbackValue);
			}
		},
		save: function (languages, namespace, data) {
			if (this.backend.save) {
				this.backend.save(languages, namespace, data);
			}
		}
	})
	.init({
		detection: {
			order: ['localStorage', 'htmlTag', 'navigator'],
			lookupLocalStorage: 'defaultLanguage',
			caches: ['localStorage'],
		},
		ns: ['language', 'common'],
		defaultNS: 'language',
		fallbackLng: 'en',
		cleanCode: true,
		load: 'languageOnly',
		interpolation: {
			escapeValue: false,
		},
		debug: false,
	});


export default i18n;
