import {DateTime} from 'luxon';
import {findBookingsInRange} from '../api';
import {bookingStatus} from '../constants/bookingStatus';
import {newOfficeDataAdded} from '../store/auth';
import {enableHQReceived, nextBookingReceived, showPurpleBarReceived} from '../store/dashboard';
import {getDataFromAPIForOffice} from './getDataFromAPIForOffice';
import sortByDate from './sortByDate';
import {limit} from '../constants/limit';

const isUserCheckedIn = (booking, userId) => {
	if (booking?.teakType?.__t === 'TeakTypeMeetingRoom' && booking?.attendees?.length > 0) {
		const attendee = booking.attendees?.find((attendee) => attendee.member.id === userId);
		const checkedIn = attendee?.checkIn && !attendee?.checkOut;
		return checkedIn;
	}
	return booking?.status === bookingStatus.checkedIn || booking?.status === bookingStatus.autoCheckedIn;
};

function getFeatureNames(teakFeaturesFromStore, teakEntity) {
	let featureNames = [];
	for (let index = 0; index < teakEntity?.teakFeatures?.length; index++) {
		const featureFromStore = teakFeaturesFromStore.find((feature) => feature.id === teakEntity?.teakFeatures[index]);
		featureNames.push(featureFromStore);
	}

	return featureNames;
}

function getAreaName(areasFromStore, areaId) {
	const areaFromStore = areasFromStore.find((area) => area._id === areaId);

	return areaFromStore?.name;
}

async function getNextBookingsAndCheckins(authData, dispatch, validCeylon) {
	// * nb of days up until we get future reservations -> so we get back reservations starting now until now() + nbOfDaysAhead
	const nbOfDaysAhead = limit.bookingsOverviewInAdvance;

	// * get current user data from store
	const userData = authData.userData;
	const offices = authData.offices;

	const startOfRange = DateTime.now().toUTC().toISO();
	const endOfRange = DateTime.now().plus({days: nbOfDaysAhead}).toUTC().toISO();

	// * get the next bookings that are in range
	let bookingsInRange;
	try {
		const bookingsInRangeResponse = await findBookingsInRange(startOfRange, endOfRange);
		const filterBookingsByOffice = bookingsInRangeResponse?.data?.filter((booking) => offices?.some((office) => office._id === booking.office));
		bookingsInRange = filterBookingsByOffice.sort((a, b) => sortByDate(b?.start?.time, a?.start?.time));
	} catch (error) {
		console.log(error);
		return {nextBookings: null, currentCheckins: null};
	}

	const unclaimedBookings = bookingsInRange.filter((booking) => {
		const isBookingUnclaimed = [
			bookingStatus.checkInOpen,
			bookingStatus.confirmed,
			bookingStatus.approvalPending,
			bookingStatus.declined,
		].includes(booking.status);

		const isAttendeeNotCheckedIn =
			booking.status === bookingStatus.checkedIn &&
			booking?.attendees?.length > 0 &&
			!booking?.attendees?.some((attendee) => attendee.member.id === userData._id && attendee?.checkIn);

		return isBookingUnclaimed || isAttendeeNotCheckedIn;
	});

	// * if booking has the checkedin status, it will be displayed under checkins
	const checkedInBookings = bookingsInRange.filter((booking) => {
		if (booking.status !== bookingStatus.checkedIn && booking.status !== bookingStatus.autoCheckedIn) {
			return false; // Early exit if status is not 'checkedIn'
		}

		return isUserCheckedIn(booking, userData._id);
	});

	// * check if we have complete data for each office of each booking
	const {updatedOffices, updatedAreas, updatedTeakFeatures} = await addOfficeOfBookingsToStore(bookingsInRange, authData, dispatch);

	let nextBookings = [];
	unclaimedBookings.forEach((booking) => {
		booking.teakEntity.areaName = getAreaName(updatedAreas, booking.teakEntity?.area);
		booking.teakEntity.featureNames = getFeatureNames(updatedTeakFeatures, booking.teakEntity);
		nextBookings.push(booking);
	});

	let checkins = [];
	checkedInBookings.forEach((booking) => {
		booking.teakEntity.areaName = getAreaName(updatedAreas, booking.teakEntity?.area);
		booking.teakEntity.featureNames = getFeatureNames(updatedTeakFeatures, booking.teakEntity);
		checkins.push(booking);
	});

	nextBookings.sort((a, b) => sortByDate(b?.start?.time, a?.start?.time));
	//only show the first 2 next bookings
	if (nextBookings?.length > 2) nextBookings = nextBookings.slice(0, 2);
	// * check if there is a next booking, if yes, check if we should show the questionnaire on the dashboard
	if (nextBookings[0]) {
		const nextBooking = nextBookings[0];
		const officeOfNextBooking = updatedOffices.find((office) => office._id === nextBooking?.office);

		// * send the latitude and longitude to the Companion App in order to trigger autocheckin
		const latitude = officeOfNextBooking?.address?.geometry?.lat;
		const longitude = officeOfNextBooking?.address?.geometry?.lng;
		if (window.ReactNativeWebView && latitude && longitude)
			window.ReactNativeWebView.postMessage(
				JSON.stringify({
					locationOfficeNextBooking: {
						latitude: latitude,
						longitude: longitude,
						officeId: officeOfNextBooking._id,
						officeName: officeOfNextBooking?.name,
					},
				}),
			);
		checkIfQuestionnaireShouldShow(nextBooking, dispatch, userData, officeOfNextBooking, validCeylon);
		dispatch({type: nextBookingReceived.type, payload: nextBookings[0]});
	}

	checkins.sort((a, b) => sortByDate(b?.start?.time, a?.start?.time));
	//only show the first 3 next checkins
	if (checkins?.length > 3) checkins = checkins.slice(0, 3);

	if (checkins[0]) {
		const checkin = checkins[0];
		const officeOfCheckin = updatedOffices.find((office) => office._id === checkin?.office);

		// * send the latitude and longitude to the Companion App in order to trigger autocheckin
		const latitude = officeOfCheckin?.address?.geometry?.lat;
		const longitude = officeOfCheckin?.address?.geometry?.lng;
		if (window.ReactNativeWebView && latitude && longitude) {
			window.ReactNativeWebView.postMessage(
				JSON.stringify({
					locationOfficeNextBooking: {
						latitude: latitude,
						longitude: longitude,
						officeId: officeOfCheckin._id,
						officeName: officeOfCheckin?.name,
					},
				}),
			);
		}
	}
	return {
		nextBookings,
		currentCheckins: checkins,
	};
}

async function addOfficeOfBookingsToStore(bookings, authData, dispatch) {
	// * get current data from store
	let teakTypeArrayFromStore = authData.teakTypeArray?.slice();
	let areasFromStore = authData.areas?.slice();
	let officesFromStore = authData.offices?.slice();
	let teakFeaturesFromStore = authData.teakFeatures?.slice();
	let areasTreeListFromStore = authData.areasTreeList?.slice();
	let aspenTypesFromStore = authData.aspenTypes?.slice();

	// * check which offices have complete data in store. complete data = teak features, areas
	const listOfCompleteOfficesIds = officesFromStore.filter((el) => el.hasAllData === true).map((el) => el._id);
	let officesWithoutData = [];

	// * check if every booking is inside an office that we have data in store for
	bookings.forEach((booking) => {
		if (!listOfCompleteOfficesIds.includes(booking.office)) {
			if (!officesWithoutData.includes(booking.office)) {
				officesWithoutData.push(booking.office);
			}
		}
	});

	if (officesWithoutData.length === 0) {
		// * if the are no offices that need the complete data, return existing data
		return {
			updatedOffices: officesFromStore,
			updatedAreas: areasFromStore,
			updatedTeakFeatures: teakFeaturesFromStore,
			updatedTeakTypes: teakTypeArrayFromStore,
		};
	}

	// * if there are any offices that do not have the complete data in store, get it and dispatch the action in order to add it to store
	for (let i = 0; i < officesWithoutData.length; i++) {
		const officeId = officesWithoutData[i];
		const {offices, areas, teakTypeArray, teakFeatures, areasTreeList, aspenTypes} = await getDataFromAPIForOffice(
			dispatch,
			areasFromStore,
			areasTreeListFromStore,
			officesFromStore,
			teakTypeArrayFromStore,
			teakFeaturesFromStore,
			aspenTypesFromStore,
			{_id: officeId},
		);
		officesFromStore = offices;
		areasFromStore = areas;
		teakTypeArrayFromStore = teakTypeArray;
		teakFeaturesFromStore = teakFeatures;
		areasTreeListFromStore = areasTreeList;
		aspenTypesFromStore = aspenTypes;
		dispatch({
			type: newOfficeDataAdded.type,
			payload: {
				offices,
				areas,
				teakTypeArray,
				teakFeatures,
				areasTreeList,
				aspenTypes,
			},
		});
	}

	// * return the updated data from store that includes missing office completed data
	return {
		updatedOffices: officesFromStore,
		updatedAreas: areasFromStore,
		updatedTeakFeatures: teakFeaturesFromStore,
		updatedTeakTypes: teakTypeArrayFromStore,
	};
}

async function checkIfQuestionnaireShouldShow(nextBooking, dispatch, userData, officeOfNextBooking, validCeylon) {
	nextBooking.officeObject = officeOfNextBooking;
	if (officeOfNextBooking?.biro?.healthQuestionnaire?.enabled) {
		const healthQuestionnaireCompletions = userData?.biro?.healthQuestionnaireCompletions;
		if (healthQuestionnaireCompletions?.length > 0) {
			let validityOfHQforSelectedOffice = officeOfNextBooking?.biro?.healthQuestionnaire?.validity;
			if (validityOfHQforSelectedOffice === undefined) validityOfHQforSelectedOffice = 172800000; //if undefined the default is 48 hrs in ms
			const lastHQCompletion = healthQuestionnaireCompletions[healthQuestionnaireCompletions.length - 1];
			if (DateTime.now().plus({minutes: 1}).toUTC() - DateTime.fromISO(lastHQCompletion).toUTC() >= validityOfHQforSelectedOffice) {
				dispatch({
					type: showPurpleBarReceived.type,
					payload: true,
				});
				const timeUntilHQEnabled = officeOfNextBooking?.biro?.healthQuestionnaire?.completionBeforeTeakReservation;
				dispatch({
					type: enableHQReceived.type,
					payload:
						DateTime.now().toUTC() >
						DateTime.fromISO(nextBooking.start.time)
							.minus({
								milliseconds: timeUntilHQEnabled,
							})
							.toUTC(),
				});
			}
		} else {
			dispatch({
				type: showPurpleBarReceived.type,
				payload: true,
			});
			const timeUntilHQEnabled = officeOfNextBooking?.biro?.healthQuestionnaire?.completionBeforeTeakReservation;
			dispatch({
				type: enableHQReceived.type,
				payload: DateTime.now().toUTC() > DateTime.fromISO(nextBooking.start.time).minus({milliseconds: timeUntilHQEnabled}).toUTC(),
			});
		}
	}

	if (officeOfNextBooking?.ceylon?.enabled && !validCeylon && officeOfNextBooking?.ceylon?.rule !== 'none') {
		dispatch({type: showPurpleBarReceived.type, payload: true});
	}
}
export {getNextBookingsAndCheckins};
