import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;

const apiUrl = {
	staticAssetsURL: process.env.REACT_APP_STATICS_ASSETS_URL,
	path: {
		biro: '/biro',
		microsoftLogin: '/biro/session/oauth2/microsoft/url',
		validateAuthToken: '/biro/session/oauth2/microsoft/validate',
		auth: '/biro/auth',
		strategies: '/strategies',
		strategy: '/strategy',
		teamsLogin: '/teams',
		accessToken: '/biro/session/renew',
		office: '/biro/office',
		area: '/biro/area',
		teak: '/biro/teak',
		teakFeature: '/biro/teak/feature',
		teakType: '/biro/teak/type',
		teakReservation: '/biro/teak/reservation',
		claimDesk: '/claim',
		member: '/biro/member',
		findMany: '/findMany',
		findNext: '/findNext',
		banner: '/banner',
		reservation: '/reservation',
		end: '/end',
		mapOutlines: '/map/outlines',
		healthQuestionnaireLastCompletion: '/healthQuestionnaireLastCompletion',
		confirmHealthQuestionnaireCompletion: '/confirmHealthQuestionnaireCompletion',
		badge: '/biro/badge',
		verificationCode: '/verificationCode',
		validateVerificationCode: '/verificationCode/validate',
		photo: '/photo',
		cancel: '/cancel',
		cancelOccurences: '/cancelOccurences',
		organization: '/biro/organization',
		whiteLabeling: '/whiteLabeling',
		userFeedback: '/biro/feedback',
		upsertVisitor: '/upsert',
		postpone: '/postpone',
		ceylon: '/biro/ceylon',
		registerCertificate: '/registerCertificate',
		validateCertificate: '/validateCertificate',
		grouped: '/grouped',
		findInRange: '/findInRange',
		completePlusStatus: '/completePlusStatus',
		getPlusStatus: '/plusStatus',
		favoriteTeakEntity: '/addFavoriteTeakEntity',
		favoriteAspenItem: '/editFavoriteAspenItem',
		reportSick: '/reportSick',
		findColleague: '/findMyColleague',
		url: '/url',
		broadcast: '/broadcast',
		hide: '/hide',
		releaseNotes: '/biro/release',
		areaMapElement: '/areaMapElement',
		countInRange: '/teak/booking/countInRange',
		category: '/category',
		updatedStatistics: '/teak/type/stats',
		emergencyNotification: '/broadcastCrisis',
		oakCrisis: '/oakCrisis/',
		updateMemberResponse: '/updateMemberResponse',
		pine: '/biro/pine',
		invitation: '/invitation',
		visitor: '/visitor',
		featureNamesTranslations: '/translations/teakFeatures.json',
		validate: '/validate',
		findColleagueCalendarView: '/findMyColleagueCalendarView',
		findOtherColleaguesCalendarView: '/findOtherColleaguesCalendarView',
		paymentIntent: '/payment/createIntent',
		context: '/context',
		favoriteOffice: '/editFavoriteOffice',
		availabilityView: '/availabilityView',
		nukiLock: (id) => `/biro/nukiLock/${id}/unlock`,
		bellTutorialEn: '/bell/DJ_BELL_ENG_V2.mp4',
		bellTutorialFr: '/bell/DJ_BELL_FR_V2.mp4',
		cedarRecord: '/biro/cedarRecord',
		cedar: '/biro/cedar',
		getCedarOptions: '/getCedarOptions',
		getCedarChangeRequests: '/getCedarChangeRequests',
		changeRequest: '/changeRequest',
		addTimeRecord: '/add',
		startTimeRecord: '/start',
		updateTimeRecord: '/update',
		deleteTimeRecord: '/delete',
		stopTimeRecord: '/stop',
		findInRangeTimeRecord: '/findInRange',
		getFirstAiders: '/getFirstAiders',
		reportFireEmergency: '/reportFireEmergency',
		i18n: '/i18n',
	},
};

export {
	getManyTeakTypes,
	getBadgeVerificationCode,
	validateBadgeVerificationCode,
	confirmHealthQuestionnaireCompletion,
	healthQuestionnaireLastCompletion,
	getOrganizationWhiteLabeling,
	getOrganization,
	submitUserFeedback,
	registerCertificate,
	validateCertificate,
	completePlusStatus,
	getPlusStatus,
	processTeakEntityToFavorites,
	getLoginStrategies,
	validateAuthCode,
	findColleagueFromEmail,
	getLoginUrl,
	loginViaTeams,
} from './additionals.js';

export { getManyTeakFeatures, getTeakFeatureById } from './teakFeature.js';

export { getAreasFromOffice, getAreaById, getBannerForArea, getMapOutline } from './areas.js';

export { getOffices, getBannerForOffice } from './offices.js';

export {
	getMicrosoftLoginUrl,
	memberChangeLastSelectedOffice,
	getMember,
	getMemberPhoto,
	loggingOut,
	updateMemberLanguage,
	generateOTP,
	validateOTP,
	updateProfileCompletionAPI,
	getPersonalizedStats,
} from './member.js';

export { getTeakEntity, bookTeakEntity, getManyEntities, reportProblemForTeakEntity, checkConflictsForRecurringBooking } from './teakEntity';

export { findBookingsInRange, checkInNow, checkOutNow, extendTeakBooking } from './teakBooking';

export { reportProblemForEntity, reportOtherProblem, reportProblemUploadPhoto, reportBugProblem } from './reportProblem';

export { getBadgesData } from './badges';

export { getAspenTypes, getAspenItems } from './aspen';

export default apiUrl;
