import BottomMenu from '../BottomMenu';
import Card from '../Cards';
import MemberBadgeModal from '../MemberBadgeModal';
import { useSelector } from 'react-redux';
import ActionSheet from '../ActionSheet';

function Page(props) {
	const showCard = useSelector((state) => state.cards.showCard);
	const showBottomMenu = useSelector((state) => state.auth.ui.showBottomMenu);

	return (
		<div className={'page app-start' + (!showBottomMenu ? ' page-no-footer' : '')}>
			<div className={showCard ? 'page-content blurred' : 'page-content'}>{props.children}</div>
			<ActionSheet />
			<Card qrCodeError={props.qrCodeError} />
			<MemberBadgeModal />
			{showBottomMenu ? <BottomMenu page={props.pageContent} /> : null}
		</div>
	);
}

export default Page;
