import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Icon from '../../Common/Icon';
import { enableCard, updateCard, updateErrorCard, updateSuccessCard } from '../../../store/cards';
import Button from '../../Common/Button';
import { useSelector } from 'react-redux';
import { reportFireEmergency } from '../../../api/reportProblem';
import { CARD } from '../../../constants/cards';

function ReportFire() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectedOffice = useSelector((state) => state.auth.data.selectedOffice)
    const officeId = selectedOffice?._id;

    const closeCard = () => {
        dispatch(enableCard(false));
    };

    const confirmFireReport = async () => {
        dispatch(updateCard(CARD.PLACEHOLDER));
        try {
            await reportFireEmergency(officeId);
            dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'reportProblem.successText'));
        } catch (error) {
            dispatch(updateErrorCard(CARD.MAIN_ERROR, 'reportProblem.errorText', error))
        }
    };

    return (
        <div className="card">
            <div className="close-card">
                <span className="close" onClick={closeCard}>
                    &times;
                </span>
            </div>
            <div className="report-fire-wrapper">
                <div className="report-fire-header">
                    <Icon cssClass={'icon-purple'} iconName={'exclamation-circle'}></Icon>
                    <h2>{t('reportFireCard.header')}</h2>
                </div>
                <div className="report-fire-details">
                    <p>{t('reportFireCard.confirmationMessage')}</p>
                </div>
                <div className="buttons-wrapper">
                    <Button
                        variant={'primary'}
                        height={'regular'}
                        width={'full'}
                        clickHandler={confirmFireReport}
                        translationKey={'reportFireCard.buttonSend'}
                    />
                </div>
            </div>
        </div>
    );
}

export default ReportFire;