import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {CARD} from '../../../constants/cards';
import {enableCard, updateErrorCard, updateSuccessCard} from '../../../store/cards';
import {getAreaNameBasedOnId, getOfficeBasedOnId, getTeakTypeObj} from '../../../store/auth';
import Icon from '../../Common/Icon';
import colorBasedOnTheme from '../../../functions/colorBasedOnTheme';
import {DateTime} from 'luxon';
import CommonTimePicker from '../../Common/CommonDateTimePicker/CommonTimePicker';
import {extendTeakBooking, getTeakEntity} from '../../../api';
import {loadNextBookings} from '../../../store/bookingsOverview/bookings';
import {loadDashboardData} from '../../../store/dashboard';
import Button from '../../Common/Button';
import {updateActionSheet} from '../../../store/auth';
import {ACTIONSHEET} from '../../../constants/actionSheets';
import {getTeakTypeName} from '../../Booking/BookingPage/AssetTypes';

function ExtendTime() {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const userData = useSelector((state) => state.auth.data.userData);
	const cardData = useSelector((state) => state.cards.data);
	const dateFormat = useSelector((state) => state.auth.data.dateFormat);
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const areaId = cardData?.entity?.area._id ? cardData?.entity?.area._id : cardData?.entity?.area;
	let areaName = useSelector(getAreaNameBasedOnId(areaId));
	if (!areaName) {
		areaName = cardData?.entity?.area?.name;
	}
	const teakTypeObj = useSelector(getTeakTypeObj(cardData.entity?.teakType));
	const teakEntityName = cardData?.entity?.name;
	const officeId = cardData?.entity?.office._id ? cardData?.entity?.office._id : cardData?.entity?.office;
	const officeObject = useSelector(getOfficeBasedOnId(officeId));
	const userLocale = useSelector((state) => state.auth.data.locale);
	const teakFeaturesFromStore = useSelector((state) => state.auth.data.teakFeatures);
	const colors = colorBasedOnTheme();
	const [endTime, setEndTime] = useState(DateTime.fromISO(cardData.booking.end.time).setZone(officeObject?.timezone).toISO());
	const [loading, setLoading] = useState(false);
	const [nextBooking, setNextBooking] = useState([]);
	const startTime = DateTime.fromISO(cardData.booking.start.time).setZone(officeObject?.timezone).toISO();
	let start = null;
	let end = null;
	let date = null;
	if (cardData.booking) {
		start = DateTime.fromISO(startTime).setZone(officeObject?.timezone).setLocale(userLocale).toLocaleString(DateTime.TIME_SIMPLE);
		end = DateTime.fromISO(endTime).setZone(officeObject?.timezone).setLocale(userLocale).toLocaleString(DateTime.TIME_SIMPLE);
		date = DateTime.fromISO(cardData.booking.end.time).setZone(officeObject?.timezone).toFormat(dateFormat);
	}
	let features = [];
	let teakFeatures = cardData.booking?.teakEntity.teakFeatures;

	if (teakFeatures && teakFeatures.length > 0) {
		teakFeatures.forEach((teakFeature) => {
			const indexInStore = teakFeaturesFromStore.findIndex((element) => element.id === teakFeature);
			if (indexInStore !== -1) features.push(teakFeaturesFromStore[indexInStore]);
		});
	}

	function renderFeatures() {
		if (!features.length) return null;
		return (
			<div className="card-entity-item">
				<span className="card-entity-label">{t('common.features') + ':'}</span>
				<div className="card-entity-labels">
					{features?.map((feature) => (
						<div className="label" key={feature.id}>
							<Icon cssClass={colors.mainThemeIconColor} iconName={feature.name.toLowerCase()} />
						</div>
					))}
				</div>
			</div>
		);
	}

	let getDisabledHours = (nextBooking) => {
		let minEndHour = DateTime.fromISO(cardData.booking.end.time).setZone(officeObject?.timezone).hour;
		const hoursArray = [];
		for (let i = 0; i < minEndHour; i++) {
			hoursArray.push(i);
		}

		let maxEndHour = DateTime.fromISO(cardData.booking.end.time).plus({hours: 8}).setZone(officeObject?.timezone).hour;
		if (nextBooking) {
			maxEndHour = DateTime.fromISO(nextBooking?.start?.time).setZone(officeObject?.timezone).hour;
			if (DateTime.fromISO(nextBooking?.start?.time).minute === 0) maxEndHour = maxEndHour - 1;
		}
		if (
			DateTime.fromISO(cardData.booking.end.time).plus({hours: 8}).setZone(officeObject?.timezone).day >
			DateTime.now().setZone(officeObject?.timezone).day
		)
			maxEndHour = 24;

		for (let i = maxEndHour + 1; i < 25; i++) {
			hoursArray.push(i);
		}

		const disabledHours = getDisabledHoursBasedOnTodaysSchedule();

		return [...hoursArray, ...disabledHours];
	};

	let getDisabledMinutes = (nextBooking) => {
		let minEndMinute = DateTime.now().setZone(officeObject?.timezone).minute;

		let minutesArray = [];
		if (DateTime.fromISO(endTime).toUTC().hour === DateTime.now().toUTC().hour) {
			for (let i = 0; i < minEndMinute; i++) {
				minutesArray.push(i);
			}
		}
		let maxEndMinute = DateTime.fromISO(cardData.booking.end.time).plus({hours: 8}).setZone(officeObject?.timezone).minute;

		if (nextBooking) maxEndMinute = DateTime.fromISO(nextBooking?.start?.time).setZone(officeObject?.timezone).minute;

		if (
			DateTime.fromISO(endTime).toUTC().hour === DateTime.fromISO(nextBooking?.start?.time).toUTC().hour ||
			DateTime.fromISO(endTime).toUTC().hour === DateTime.fromISO(cardData.booking.end.time).plus({hours: 8}).toUTC().hour
		) {
			for (let i = maxEndMinute; i < 60; i++) {
				minutesArray.push(i);
			}
		}

		const disabledMinutes = getDisabledMinutesBasedOnTodaysSchedule();

		return [...minutesArray, ...disabledMinutes];
	};

	const getDisabledHoursBasedOnTodaysSchedule = () => {
		const todaySchedule = getTodaysSchedule();
		if (!todaySchedule) {
			return [];
		}

		const disabledHours = [];
		for (let i = 0; i < 24; i++) {
			const hourInMs = i * 60 * 60 * 1000;
			const isInOfficeHours = todaySchedule?.periods?.some((period) => {
				return hourInMs >= period?.open && hourInMs <= period?.close;
			});
			if (!isInOfficeHours) {
				disabledHours.push(i);
			}
		}
		return disabledHours;
	};

	const getDisabledMinutesBasedOnTodaysSchedule = () => {
		const todaySchedule = getTodaysSchedule();
		if (!todaySchedule) {
			return [];
		}

		const disabledMinutes = [];
		const selectedHourInMs = DateTime.fromISO(endTime).hour * 60 * 60 * 1000;
		const selectedMinuteInMs = DateTime.fromISO(endTime).minute * 60 * 1000;
		const selectedMs = selectedHourInMs + selectedMinuteInMs;

		const currentPeriod = todaySchedule?.periods?.find((period) => {
			return selectedMs >= period?.open && selectedMs <= period?.close;
		});

		if (currentPeriod) {
			const currentPeriodEnd = currentPeriod?.close;
			const currentPeriodEndHour = Math.floor(currentPeriodEnd / (60 * 60 * 1000));
			const currentPeriodEndMinute = Math.floor((currentPeriodEnd % (60 * 60 * 1000)) / (60 * 1000)) + 15;

			if (selectedHourInMs !== currentPeriodEndHour * 60 * 60 * 1000) {
				return [];
			}

			for (let i = currentPeriodEndMinute; i < 60; i += 15) {
				disabledMinutes.push(i);
			}
		}

		return disabledMinutes;
	};

	const getTodaysSchedule = () => {
		if (!selectedOffice?.teak?.restrictBookingToOfficeHours) {
			return null;
		}

		const dayOfWeek = DateTime.now().setZone(officeObject?.timezone).weekday;
		const todaySchedule = selectedOffice?.openingHours?.office?.schedule?.find((day) => day.weekday === dayOfWeek);

		if (!todaySchedule?.periods || todaySchedule?.open24Hours || todaySchedule?.periods?.length === 0) {
			return null;
		}

		return todaySchedule;
	}


	const isButtonDisabled = () => {
		const todaySchedule = getTodaysSchedule();
		if (!todaySchedule) {
			return false;
		}

		const selectedHourInMs = DateTime.fromISO(endTime).hour * 60 * 60 * 1000;
		const selectedMinuteInMs = DateTime.fromISO(endTime).minute * 60 * 1000;
		const selectedMs = selectedHourInMs + selectedMinuteInMs;

		const endTimeIsWithinASchedule = todaySchedule?.periods?.some((period) => {
			return selectedMs >= period?.open && selectedMs <= period?.close;
		});

		return !endTimeIsWithinASchedule;
	};

	const extendBooking = () => {
		setLoading(true);
		let until = DateTime.fromISO(endTime).toUTC().toISO();

		extendTeakBooking(cardData.booking._id, until)
			.then((response) => {
				dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'successMessages.extendTimeSuccessful'));
				dispatch(loadNextBookings(true));
				dispatch(loadDashboardData(true));
			})
			.catch((error) => {
				dispatch(updateErrorCard(CARD.MAIN_ERROR, 'errorMessages.general', error));
			});
	};

	const closeCard = () => {
		dispatch(enableCard(false));
	};

	useEffect(() => {
		getTeakEntity(cardData.entity._id).then((response) => {
			if (response.data?.teakBookingDocs?.length > 0) {
				const nextBookings = response.data.teakBookingDocs;
				nextBookings.sort((a, b) => DateTime.fromISO(a?.start.time).toMillis() - DateTime.fromISO(b?.start.time).toMillis());
				let nextBookingsFiltered = nextBookings.filter((booking) => booking.member !== userData._id);
				setNextBooking(nextBookingsFiltered[0]);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleBackButton = () => {
		dispatch(enableCard(false));
		dispatch(updateActionSheet(ACTIONSHEET.BOOKING_OPTIONS_MENU));
	};

	return (
		<div className="card options-card">
			<div className="close-card">
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="confirmation-wrapper">
				<p className="success-text header-bottom-line">{t('common.extendBooking')}</p>

				<div className="card-entity-wrapper">
					<div className="card-entity-item">
						<span className="card-entity-label">{getTeakTypeName(teakTypeObj, t)}</span>:
						<span className="card-entity-name card-entity-name-highlighted">{teakEntityName}</span>
					</div>

					<div className="card-entity-item">
						<span className="card-entity-label">{t('common.location')}</span>:
						<span className="card-entity-name">{officeObject.name}</span> -<span className="card-entity-name">{areaName}</span>
					</div>

					<div className="card-entity-item">
						<span className="card-entity-label">{t('common.time') + '/' + t('common.date') + ':'}</span>
						<span className="card-entity-time">
							{start} - {end}, {date}
						</span>
					</div>
					{renderFeatures()}
				</div>
				<div className="card-entity-time-wrapper">
					<CommonTimePicker
						timeLabel={'common.dateTimePicker.startTime'}
						value={startTime}
						disabled={true}
						timezone={officeObject?.timezone}
						clockstyle={true}
					/>
					<CommonTimePicker
						timeLabel={'common.dateTimePicker.endTime'}
						value={endTime}
						changeValue={(value) => setEndTime(value)}
						timezone={officeObject?.timezone}
						disabledHours={() => getDisabledHours(nextBooking)}
						disabledMinutes={() => getDisabledMinutes(nextBooking)}
						minuteStep={15}
						clockstyle={true}
					/>
				</div>
				<div className="buttons-wrapper">
					<Button
						variant={'primary'}
						height={'regular'}
						width={'full'}
						clickHandler={extendBooking}
						loading={loading}
						translationKey={'common.buttons.confirm'}
						disabled={isButtonDisabled()}
					/>

					<Button
						variant={'secondary'}
						height={'regular'}
						width={'full'}
						clickHandler={() => handleBackButton()}
						translationKey={'common.backButton'}
					/>
				</div>
			</div>
		</div>
	);
}

export default ExtendTime;
